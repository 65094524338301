<template>
  <v-container class="max-w-full">

    <v-simple-table class="discounts-table" style="vertical-align: middle">
      <template v-slot:default>
        <tbody>
        <draggable
          :disabled="!enabled"
          :list="reseller_discounts"
          class="list-group cursor-move"
          ghost-class="ghost"
          @end="dragging = false"
          @start="dragging = true"
        >
          <tr
            v-for="(item, index) in reseller_discounts"
            :key="index"
          >

            <td class="pb-2" style="width: 25%">
              <div class="list-group-item d-flex items-center pa-2" style="height: 58px;">
                <span class="mr-3">De la</span>
                <v-text-field
                  v-model="item.from"
                  class="mt-0"
                  dense
                  hide-details
                  label="De la"
                  outlined
                ></v-text-field>
              </div>
            </td>

            <td class="pb-2" style="width: 25%">
              <div class="list-group-item d-flex items-center pa-2" style="height: 58px;">
                <span class="mr-3">pana la</span>
                <v-text-field
                  v-model="item.to"
                  class="mt-0"
                  dense
                  hide-details
                  label="Pana la"
                  outlined
                ></v-text-field>
              </div>
            </td>

            <td class="pb-2" style="width: 5%">
              <div class="list-group-item d-flex items-center pa-2 justify-center" style="height: 58px;">-</div>
            </td>

            <td class="pb-2" style="width: 25%">
              <div class="list-group-item d-flex items-center pa-2" style="height: 58px;">
                <span class="mr-3">Discount</span>
                <v-text-field
                  v-model="item.discount"
                  class="mt-0"
                  dense
                  hide-details
                  label="Discount"
                  outlined
                  suffix="%"
                ></v-text-field>
              </div>
            </td>

            <td class="pb-2" style="width: 40%">
              <div class="list-group-item d-flex items-center pa-2" style="height: 58px;">&nbsp;</div>
            </td>

            <td class="pb-2" style="width: 20%">
              <div class="list-group-item d-flex items-center justify-end pa-2" style="height: 58px;">
                <v-btn
                  class="mx-2"
                  color="red"
                  icon
                >
                  &nbsp;
                  <vs-icon icon="fa-trash" icon-pack="fal"></vs-icon>
                  &nbsp;
                </v-btn>
              </div>
            </td>

          </tr>
        </draggable>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="text-right">
      <v-col class="pt-0 justify-end">
        <v-btn depressed
               @click="addItem">
          Adauga cota noua
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          color="primary"
          dark
          large
          @click="saveSettings"
        >
          Salveaza
        </v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'discounts',
  components: {
    draggable
  },
  data () {
    return {
      enabled: true,
      dragging: false,
      reseller_discounts: []
    }
  },
  methods: {
    addItem () {
      const discountData = {
        from: '',
        to: '',
        discount: ''
      }
      this.reseller_discounts.push(discountData)
    },
    getDiscountSettings () {
      this.$http.get('settings?settings[]=reseller_discounts')
        .then(({data}) => {
          this.reseller_discounts = JSON.parse(data.reseller_discounts)
        })
    },
    saveSettings () {
      this.$http.post('settings', {
        settings: [
          {
            key: 'reseller_discounts',
            value: JSON.stringify(this.reseller_discounts)
          }
        ]
      })
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, {root: true})
        })
        .catch(() => {
          this.$store.dispatch('notify', {
            title: 'Eroare!',
            text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          }, {root: true})
        })
    }
  },
  created () {
    this.getDiscountSettings()
  }
}
</script>

<style lang="scss" scoped>
.list-group-item {
  background-color: #f8f8f8;
}

.v-input--is-label-active input {
  margin-top: 0;
}
</style>
