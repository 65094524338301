<template>
  <v-tabs class="general-settings" left vertical v-model="tab">
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-user-tie" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Agent implicit</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-address-book" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Date de contact</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Scoring</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-user-circle" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Pagina de conturi</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-gifts" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Black Friday</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-shopping-cart" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Comanda fara cont</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-badge-percent" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Oferta rapida</span>
    </v-tab>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <default-agent />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <contact-data />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <client-scoring />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <account-text />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <black-friday />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <anonymous-checkout />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <v-card elevation="3" rounded>
        <quick-offer />
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import DefaultAgent from '@/views/Settings/GeneralSettings/components/defaultAgent'
import ContactData from '@/views/Settings/GeneralSettings/components/contactData'
import QuickOffer from '@/views/Settings/GeneralSettings/components/quickOffer'
import Discounts from '@/views/Settings/GeneralSettings/components/discounts'
import ClientScoring from '@/views/Settings/GeneralSettings/components/clientScoring'
import PageBody from '@/views/PageManagement/components/PageBody'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import AccountText from '@/views/Settings/GeneralSettings/components/accountText'
import BlackFriday from './components/blackFriday.vue'
import AnonymousCheckout from './components/anonymousCheckout.vue'

export default {
  name: 'index',
  components: {
    AccountText,
    ErrorMessages,
    PageBody,
    ClientScoring,
    Discounts,
    ContactData,
    QuickOffer,
    DefaultAgent,
    BlackFriday,
    AnonymousCheckout
  },
  data() {
    return {
      tab: ''
    }
  },
  methods: {
    loadTab() {
      if (window.location.href.includes('invoicingSeries')) {
        this.tab = 6
      }else{
        this.tab = 0
      }
    }
  },
  created() {
    this.loadTab()
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style>
.general-settings .v-tabs-slider-wrapper {
  right: 0;
  left: inherit !important;
}
</style>
