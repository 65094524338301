<template>
  <v-container class="max-w-full">
    <v-row class="items-center">
      <v-col>
        <v-card-title>Continut Distribuitor</v-card-title>
        <page-body v-model="form.resellerText" class="mb-6" label="">
          <error-messages :errors="errors.resellerText"/>
        </page-body>
      </v-col>
    </v-row>

    <v-row class="items-center">
      <v-col cols="6">
        <v-card-title>Continut Companie</v-card-title>
        <page-body v-model="form.companyText" class="mb-6" label="">
          <error-messages :errors="errors.companyText"/>
        </page-body>
      </v-col>

      <v-col cols="6">
        <v-card-title>Continut End-user</v-card-title>
        <page-body v-model="form.endUserText" class="mb-6" label="">
          <error-messages :errors="errors.endUserText"/>
        </page-body>
      </v-col>
    </v-row>

    <v-row class="items-center">
      <v-col cols="6">
        <v-card-title>Continut Instituie Publica</v-card-title>
        <page-body v-model="form.publicText" class="mb-6" label="">
          <error-messages :errors="errors.publicText"/>
        </page-body>
      </v-col>

      <v-col cols="6">
        <v-card-title>Continut IT-ist</v-card-title>
        <page-body v-model="form.itText" class="mb-6" label="">
          <error-messages :errors="errors.itText"/>
        </page-body>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          color="primary"
          dark
          large
          @click="saveSettings"
        >
          Salveaza
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageBody from "@/views/PageManagement/components/PageBody";
import ErrorMessages from "@/components/general-form/ErrorMessages";
export default {
  name: "accountText",
  components: {ErrorMessages, PageBody},
  data() {
    return {
      form: {
        resellerText: '',
        companyText: '',
        endUserText: '',
        publicText: '',
        itText: ''
      },
      errors: {}
    }
  },
  methods: {
    saveSettings () {
      this.$http.post('settings', {
        settings: [
          {
            key: 'default_public_agent',
            value: this.default_public_agent
          },
          {
            key: 'default_reseller_agent',
            value: this.default_reseller_agent
          }
        ]
      })
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, {root: true})
        })
        .catch(() => {
          this.$store.dispatch('notify', {
            title: 'Eroare!',
            text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          }, {root: true})
        })
    }
  }
}
</script>

<style scoped>

</style>
