<template>
  <v-container class="max-w-full">
    <v-row class="items-center">
      <v-col class="pt-0" cols="4">
        <p class="mb-0 text-lg">Permite comenzi fara cont</p>
      </v-col>
      <v-col class="pt-0" cols="8">
        <v-switch
          v-model="enableAnonymousCheckout"
          :loading="disableAnonymousCheckoutButton"
          @change="onAnonymousCheckoutButtonChange"
        ></v-switch>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "anonymousCheckout",
  data() {
    return {
      enableAnonymousCheckout: false,
      disableAnonymousCheckoutButton: true,
    };
  },
  methods: {
    onAnonymousCheckoutButtonChange() {
      this.$http.post('settings', {
        settings: [
          {
            key: 'cit_enable_anonymous_checkout',
            value: this.enableAnonymousCheckout
          },
        ]
      })
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, {root: true})
        })
        .catch((error) => {
          this.generalError = error.response.data.message
          this.errors = true
        });
    },
    getAnonymousCheckoutSettings() {
      return this.$http.get("settings?settings[]=cit_enable_anonymous_checkout");
    },
  },
  mounted() {
    this.getAnonymousCheckoutSettings()
      .then(({ data }) => {
        console.log(data);
        this.enableAnonymousCheckout = Boolean(Number(data.cit_enable_anonymous_checkout));
        this.disableAnonymousCheckoutButton = false;
      })
      .catch((error) => {
        this.$vs.notify({
          title: "Eroare",
          text: error.response.data.message,
          color: "danger",
        });
      });
  },
};
</script>
