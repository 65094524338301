<template>
  <v-container class="max-w-full">
    <v-row class="items-center">
      <v-col class="pt-0" cols="2">
        <p class="mb-0 text-lg">Adresa contact</p>
      </v-col>
      <v-col class="pt-0" cols="10">
        <v-text-field
          v-model="cit_contact_address"
          hide-details
          single-line
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="items-center">
      <v-col class="pt-0" cols="2">
        <p class="mb-0 text-lg">CUI</p>
      </v-col>
      <v-col class="pt-0" cols="10">
        <v-text-field
          v-model="cit_vat_number"
          hide-details
          single-line
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="items-center">
      <v-col class="pt-0" cols="2">
        <p class="mb-0 text-lg">Nr. REG.</p>
      </v-col>
      <v-col class="pt-0" cols="10">
        <v-text-field
          v-model="cit_reg_number"
          hide-details
          single-line
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="items-center">
      <v-col class="pt-0" cols="2">
        <p class="mb-0 text-lg">Adresa</p>
      </v-col>
      <v-col class="pt-0" cols="10">
        <v-text-field
          v-model="cit_address"
          hide-details
          single-line
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="items-center">
      <v-col class="pt-0" cols="2">
        <p class="mb-0 text-lg">Adresa</p>
      </v-col>
      <v-col class="pt-0" cols="10">
        <v-text-field
          v-model="cit_bank"
          hide-details
          single-line
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="items-center">
      <v-col class="pt-0" cols="2">
        <p class="mb-0 text-lg">Adresa</p>
      </v-col>
      <v-col class="pt-0" cols="10">
        <v-text-field
          v-model="cit_bank_iban"
          hide-details
          single-line
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="items-center">
      <v-col class="pt-0" cols="2">
        <p class="mb-0 text-lg">Pers. de contact</p>
      </v-col>
      <v-col class="pt-0" cols="10">
        <v-text-field
          v-model="cit_contact_person"
          hide-details
          single-line
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <vs-alert :active="errors" color="danger" icon="new_releases">
          <span>{{ generalError }}</span>
        </vs-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          color="primary"
          dark
          large
          @click="saveSettings"
        >
          Salveaza
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'contactData',
  data () {
    return {
      cit_contact_address: '',
      cit_vat_number: '',
      cit_reg_number: '',
      cit_address: '',
      cit_bank: '',
      cit_bank_iban: '',
      cit_contact_person: '',
      generalError: '',
      errors: false
    }
  },
  methods: {
    getContactSettings () {
      this.$http.get('settings?settings[]=cit_contact_address&settings[]=cit_vat_number&settings[]=cit_reg_number&settings[]=cit_address&settings[]=cit_bank&settings[]=cit_bank_iban&settings[]=cit_contact_person')
        .then(({data}) => {
          this.cit_contact_address = data.cit_contact_address
          this.cit_vat_number = data.cit_vat_number
          this.cit_reg_number = data.cit_reg_number
          this.cit_address = data.cit_address
          this.cit_bank = data.cit_bank
          this.cit_bank_iban = data.cit_bank_iban
          this.cit_contact_person = data.cit_contact_person
        })
    },
    saveSettings () {
      this.$http.post('settings', {
        settings: [
          {
            key: 'cit_contact_address',
            value: this.cit_contact_address
          },
          {
            key: 'cit_vat_number',
            value: this.cit_vat_number
          },
          {
            key: 'cit_reg_number',
            value: this.cit_reg_number
          },
          {
            key: 'cit_address',
            value: this.cit_vat_number
          },
          {
            key: 'cit_bank',
            value: this.cit_bank
          },
          {
            key: 'cit_bank_iban',
            value: this.cit_bank_iban
          },
          {
            key: 'cit_contact_person',
            value: this.cit_contact_person
          }
        ]
      })
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, {root: true})
        })
        .catch((error) => {
          this.generalError = error.response.data.message
          this.errors = true
        })
    }
  },
  created () {
    this.getContactSettings()
  }
}
</script>
