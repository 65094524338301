<template>
  <v-container class="max-w-full">
    <v-row class="items-center">
      <v-col class="pt-0" cols="4">
        <p class="mb-0 text-lg">Activeaza tema de Black Friday</p>
      </v-col>
      <v-col class="pt-0" cols="8">
        <v-switch
          v-model="enableBlackFridayTheme"
          :loading="disableBlackFridayButton"
          @change="onBlackFridayThemeButtonChange"
        ></v-switch>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "blackFriday",
  data() {
    return {
      enableBlackFridayTheme: false,
      disableBlackFridayButton: true,
    };
  },
  methods: {
    onBlackFridayThemeButtonChange() {
      this.$http.post('settings', {
        settings: [
          {
            key: 'cit_enable_black_friday_theme',
            value: this.enableBlackFridayTheme
          },
        ]
      })
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, {root: true})
        })
        .catch((error) => {
          this.generalError = error.response.data.message
          this.errors = true
        });
    },
    getBlackFridaySettings() {
      return this.$http.get("settings?settings[]=cit_enable_black_friday_theme");
    },
  },
  mounted() {
    this.getBlackFridaySettings()
      .then(({ data }) => {
        console.log(data);
        this.enableBlackFridayTheme = Boolean(Number(data.cit_enable_black_friday_theme));
        this.disableBlackFridayButton = false;
      })
      .catch((error) => {
        this.$vs.notify({
          title: "Eroare",
          text: error.response.data.message,
          color: "danger",
        });
      });
  },
};
</script>
