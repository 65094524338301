<template>
  <v-container class="max-w-full">
    <v-row class="items-center">
      <v-col class="pt-0" cols="6">
        <p class="mb-0 text-lg">Serie</p>
        <v-text-field v-model="serial_number" hide-details single-line></v-text-field>
      </v-col>
      <v-col class="pt-0" cols="6">
        <p class="mb-0 text-lg">Numar curent</p>
        <v-text-field v-model="current_number" hide-details single-line></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <vs-alert :active="errors" color="danger" icon="new_releases">
          <span>{{ generalError }}</span>
        </vs-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn color="primary" dark large @click="saveSettings">
          Salveaza
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'quickOffer',
  data() {
    return {
      current_number: '',
      serial_number: '',
      errors: false
    }
  },
  methods: {
    getQuickOfferSettings() {
      this.$http.get('settings?settings[]=current_number&settings[]=serial_number')
        .then(({ data }) => {
          this.current_number = data.current_number
          this.serial_number = data.serial_number
        })
    },
    saveSettings() {
      this.$http.post('settings', {
        settings: [
          {
            key: 'current_number',
            value: this.current_number
          },
          {
            key: 'serial_number',
            value: this.serial_number
          },
        ]
      })
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, { root: true })
        })
        .catch((error) => {
          this.generalError = error.response.data.message
          this.errors = true
        })
    }
  },
  created() {
    this.getQuickOfferSettings()
  }
}
</script>
